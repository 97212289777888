import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModAnimation from "../../../components/Chronology/Modules/ModAnimation";
import ModCovidHighlight from "../../../components/Chronology/Modules/ModCovidHighlight";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";

export const frontmatter = {
  title: "Día 29",
  week: "Semana 5",
  month: "abr",
  day: "11",
  monthNumber: "04",
  date: "2020-04-11",
  path: "/cronologia/semana-05#dia-11-abr",
};

const Day29 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModImage
          src="/images/svg/11_abr_aduana.svg"
          alt="prórroga por otros 14 días"
        />
        <ModText>
          También ha decretado la <strong>prórroga por otros 14 días</strong>{" "}
          del restablecimiento de los controles en las fronteras terrestres con
          Francia y Portugal.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-11"} />
        <ModCovidHighlight>
          EE.UU triplica a España en número de casos confirmados.
        </ModCovidHighlight>
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 4.830 nuevos positivos y 605 personas fallecidas.
          Permanecen hospitalizadas 67.572 personas, 993 más que el día
          anterior, y 3.503 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.482, lo que supone un aumento de 111 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,1 % y la tasa de recuperados del 35,5 %.
        </ModText>
        <ModText>
          Los datos registrados hasta la fecha muestran un descenso del 32% en
          el total de casos confirmados, respecto a la semana anterior. Mientras
          que en la semana del 30 de marzo al 5 de abril se confirmaron 51.962
          nuevos casos, esta semana se han notificado 35.260, lo que supone una
          disminución de 16.702 casos.
        </ModText>
        <ModGraph
          src="1104-graph"
          alt="grafica evolución semanal casos confirmados"
        />
        <ModText>
          El Gobierno ha distribuido{" "}
          <strong>un millón más de test rápidos</strong> de detección de
          anticuerpos entre las comunidades autónomas para diagnosticar de forma
          precoz el coronavirus.
        </ModText>
        <ModAnimation svg="/images/anim/11_abr_gas_station.svg" />
        <ModText>
          El Gobierno establece medidas para{" "}
          <strong>asegurar el suministro de carburantes</strong> en las
          estaciones de servicio y postes marítimos en todo el territorio
          nacional, y con especial atención a los territorios con menor densidad
          de población, mientras se prolongue el estado de alarma.
        </ModText>
        <ModImage src="/images/svg/11_abr_city.svg" alt="plan de vivienda" />
        <ModText>
          El Ministerio de Transportes, Movilidad y Agenda Urbana (Mitma), ha
          desarrollado en el Plan Estatal de Vivienda 2018-2021, la nueva ayuda
          para el pago del alquiler de vivienda habitual a arrendatarios
          vulnerables sobrevenidos por COVID-19.
        </ModText>
        <ModText>
          En apenas una semana, 347 españoles han ofrecido su alojamiento a
          través de la plataforma creada por el Ministerio de Asuntos
          Exteriores, Unión Europea y Cooperación, denominada "Aloja".
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day29;
