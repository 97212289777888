import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDatafactCCAA from "../../../components/Chronology/Modules/ModDatafactCCAA";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 35",
  week: "Semana 5",
  month: "abr",
  day: "17",
  monthNumber: "04",
  date: "2020-04-17",
  path: "/cronologia/semana-05#dia-17-abr",
};
const Day35 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModDatafactCCAA
          fecha={"2020-04-17"}
          ccaa="es-md"
          customText="Casos Confirmados"
          customNumber="50.694"
        />
        <ModTwoCols
          src="/images/svg/28_mar_avion.svg"
          alt="Plan de recuperación europeo"
          small={true}
        >
          El Ministerio de Asuntos Exteriores, Unión Europea y Cooperación ha
          facilitado el regreso de casi 24.000 españoles desde que se decretó en
          España el estado de alarma a consecuencia de la pandemia de COVID-19.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/25_mar_alojamiento_turistico.svg"
          alt="Aloja acoge a 415 españoles en el extranjero"
          small={false}
          inverted={true}
        >
          Por otra parte, la Plataforma ALOJA, ha facilitado que 427 españoles
          hayan puesto su alojamiento al servicio de los españoles que se
          encuentran en el extranjero, y que 415 lo hayan solicitado, desde su
          puesta en funcionamiento el 3 de abril.
        </ModTwoCols>
        <ModTwoCols
          src="/images/svg/17_abr_acuerdo-mediterraneo.svg"
          alt="Plan de recuperación europeo"
          small={false}
        >
          <strong>España, Francia y Portugal</strong> han expresado su apoyo a
          un ambicioso <strong>plan de recuperación europeo</strong>, financiado
          conjuntamente y coherente con las prioridades europeas. En particular,
          la lucha contra el cambio climático, la estrategia digital de la
          Unión, y el desarrollo de una Europa más social.
        </ModTwoCols>
        <ModDataEu fecha={"2020-04-17"} />
      </ContentLeft>

      <ContentRight>
        <ModGraph
          name="17-04-Confirmados-recuperados"
          alt="graficos"
        />
        <ModTwoCols
          src="/images/svg/26_mar_mascarilla.svg"
          alt="siete millones de mascarillas"
          small={false}
        >
          Con el último envío que ha recibido esta semana España, ya son más de{" "}
          <strong>siete millones de mascarillas</strong> las que ha adquirido el
          Ministerio de Transportes, Movilidad y Agenda Urbana para repartir
          entre las comunidades autónomas.
        </ModTwoCols>
        <ModText>
          El{" "}
          <strong>
            Ministerio de Consumo y las asociaciones de consumidores y usuarios
          </strong>{" "}
          han acordado crear una{" "}
          <strong>mesa de trabajo de carácter técnico</strong> con el objetivo
          de hacer un seguimiento permanente a las reclamaciones y defender los
          derechos de los consumidores. Además, también se tratará de facilitar
          acuerdos con empresas en aquellas compras o servicios que no se han
          prestado a la causa de la crisis sanitaria.
        </ModText>
        <ModText>
          El <strong>Alto Comisionado contra la Pobreza Infantil</strong> se ha
          reunido para analizar la situación actual y los retos de futuro a los
          que se va a enfrentar la infancia vulnerable por la crisis de
          COVID-19, tanto a corto como a medio y largo plazo.
        </ModText>
        <ModImage src="/images/svg/02_abr_pesca.svg" alt="pesca" />
        <ModText>
          Por último, la Unión Europea ha aprobado una{" "}
          <strong>
            modificación del reglamento del Fondo Europeo Marítimo y de Pesca
            (FEMP)
          </strong>{" "}
          que va a permitir una mejor utilización de los recursos, con el
          objetivo de hacer frente a las consecuencias socioeconómicas que la
          situación generada por el COVID-19 está teniendo en el sector de la
          pesca y la acuicultura.
        </ModText>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day35;
