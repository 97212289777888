import React from "react";
import LayoutWeek from "../../../components/Chronology/LayoutWeek";
import Day29 from "./dia-11-abr";
import Day30 from "./dia-12-abr";
import Day31 from "./dia-13-abr";
import Day32 from "./dia-14-abr";
import Day33 from "./dia-15-abr";
import Day34 from "./dia-16-abr";
import Day35 from "./dia-17-abr";

import SecWeekly, {
  SecWeeklyColumn,
} from "../../../components/Chronology/Modules/SecWeekly";
import ModMegaBanner from "../../../components/Chronology/Modules/ModMegaBanner";
import ModAlertBanner from "../../../components/Chronology/Modules/ModAlertBanner";
import ModDottedLine from "../../../components/Chronology/Modules/ModDottedLine";
import { StrongGold } from "../../../components/Textstyles/Styles";
import ModImage from "../../../components/Chronology/Modules/ModImage";

const Week = (props) => {
  return (
    <LayoutWeek {...props}>
      <SecWeekly
        period="11 Abril - 17 de Abril"
        week="Semana 5 de confinamiento"
      >
        <SecWeeklyColumn>
          España alcanza el <strong>pico de la pandemia</strong>
          <ModDottedLine />
          Europa supera el <strong>1.000.000 de casos confirmados</strong>
          <ModDottedLine />
          Se aprueban medidas excepcionales para el refuerzo del{" "}
          <strong>Sistema Nacional de Salud</strong>
          <ModDottedLine />
          <strong>Datos de móviles</strong> para estudiar la eficacia del
          confinamiento sobre la <strong>dispersión de COVID-19</strong>
        </SecWeeklyColumn>
        <SecWeeklyColumn>
          <ModImage
            src="/images/svg/semana5-casosconfirmados.svg"
            alt="casos confirmados"
          />
        </SecWeeklyColumn>
      </SecWeekly>

      <Day35 {...props} />
      <Day34 {...props} />
      <ModAlertBanner color="brand04">
        Europa sobrepasa el millón de contagiados con el coronavirus SARS-CoV-2
      </ModAlertBanner>
      <Day33 {...props} />
      <Day32 {...props} />
      <ModMegaBanner>
        Los datos notificados hasta la fecha confirman que{" "}
        <StrongGold>España ha alcanzado el pico</StrongGold> de la pandemia
      </ModMegaBanner>
      <Day31 {...props} />
      <Day30 {...props} />
      <Day29 {...props} />
    </LayoutWeek>
  );
};

export default Week;
