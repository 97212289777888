import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";

export const frontmatter = {
  title: "Día 33",
  week: "Semana 5",
  month: "abr",
  day: "15",
  monthNumber: "04",
  date: "2020-04-15",
  path: "/cronologia/semana-05#dia-15-abr",
};
const Day33 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModText>
          En el ámbito económico, la crisis de COVID-19 ha tenido un fuerte
          impacto en la afiliación a la Seguridad Social, ya que desde el 12 de
          marzo hasta la actualidad, se han perdido tantos empleos como en los
          102 días de mayor destrucción de puestos de la crisis de 2008-2009.
        </ModText>
        <ModText>
          Entre las consecuencias que ha conllevado la crisis, destacan:
        </ModText>
        <ModDotList
          text1="Casi 4 millones de personas, el 16% de
          la población activa, están incluidas en ERTEs"
          text2="El régimen de autónomos ha perdido
          56.000 afiliados"
          text3="950.000 solicitudes para la prestación
          extraordinaria por cese de actividad"
          text4="400.000 personas beneficiarias de
          prestaciones de Incapacidad Temporal asimiladas a accidente de
          trabajo por COVID-19"
          text5="92.000 aplazamientos de las cotizaciones
          a la Seguridad Social"
        />
        <ModTwoCols
          src="/images/svg/14_04_cultura.svg"
          alt="cultura y deportes"
          small={false}
        >
          El ministro de Cultura y Deporte, planteará mañana un Pacto de Estado
          por la Cultura, que tendrá como objetivos principales la protección,
          durante la crisis sanitaria, de los sectores de la cultura; su
          relanzamiento tras la pandemia, y el{" "}
          <strong>
            reconocimiento de la cultura como un bien de primera necesidad{" "}
          </strong>
          .
        </ModTwoCols>
        <ModText>
          El Ministerio de Transportes, Movilidad y Agenda Urbana (Mitma) sigue
          comprometido con el sector del transporte terrestre con un{" "}
          <strong>nuevo reparto de mascarillas</strong>, adquiridas a través de
          Puertos del Estado. Con este segundo reparto, serán más de 3,3
          millones de mascarillas las distribuidas entre los trabajadores del
          sector del transporte terrestre.
        </ModText>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-15"} />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 5.092 nuevos positivos y 567 personas fallecidas.
          Permanecen hospitalizadas 75.752 personas, 523 más que el día
          anterior, y 2.777 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.851, lo que supone un aumento de 74 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,5 % y la tasa de recuperados del 39,1 %.
        </ModText>
        <ModText>
          Los devastadores efectos del virus han tenido incidencias distintas en
          las diferentes comunidades autónomas. No sólo el impacto, también su
          evolución está siendo diferente. Mientras las comunidades más
          castigadas muestran una fuerte tendencia de reducción, otras como
          Castilla La Mancha, están formando una tendencia plana o con cierto
          repunte.
        </ModText>
        <ModGraph name="1504-Vision-ES-CM" alt="graficos" />
        <ModText>
          Afortunadamente, el descenso en los casos confirmados hace esperar que
          no tarde en notarse el descenso en los fallecimientos.
        </ModText>
        <ModText>
          El Instituto de Salud Carlos III (ISCIII) ha aprobado{" "}
          <strong>
            cuatro nuevos proyectos de investigación frente al SARS-CoV-2
          </strong>
          , de manera que son ya 15 las investigaciones respaldadas por este
          instrumento puesto en marcha para hacer frente al nuevo coronavirus y
          la enfermedad que provoca.
        </ModText>
        <ModImage src="/images/svg/1304-court.svg" alt="administración de justicia" />
      </ContentRight>
    </LayoutDay>
  );
};
export default Day33;
