import React from "react";
import LayoutDay from "../../../components/Chronology/LayoutDay";
import ContentLeft from "../../../components/Chronology/Modules/ContentLeft";
import ContentRight from "../../../components/Chronology/Modules/ContentRight";
import ModCCAATable from "../../../components/Chronology/Modules/ModCCAATable";
import ModTwoCols from "../../../components/Chronology/Modules/ModTwoCols";
import ModText from "../../../components/Chronology/Modules/ModText";
import ModImage from "../../../components/Chronology/Modules/ModImage";
import ModReferenceList from "../../../components/Chronology/Modules/ModReferenceList";
import ReferenceRow from "../../../components/Chronology/Modules/ReferenceRow";
import ModDashedLine from "../../../components/Chronology/Modules/ModDashedLine";
import ModDotList from "../../../components/Chronology/Modules/ModDotList";
import ModDataEu from "../../../components/Chronology/Modules/ModDataEu";
import ModGraph from "../../../components/Chronology/Modules/ModGraph";

export const frontmatter = {
  title: "Día 32",
  week: "Semana 5",
  month: "abr",
  day: "14",
  monthNumber: "04",
  date: "2020-04-14",
  path: "/cronologia/semana-05#dia-14-abr",
};
const Day32 = (props) => {
  return (
    <LayoutDay frontmatter={frontmatter} {...props}>
      <ContentLeft>
        <ModCCAATable fecha={frontmatter.date} />
        <ModTwoCols
          src="/images/svg/14_abr_Metereología.svg"
          alt="variables meteorológicas"
          small={true}
        >
          La Agencia Estatal de Meteorología (AEMET) y el Instituto de Salud
          Carlos III (ISCIII) han empezado a investigar conjuntamente qué{" "}
          <strong>variables meteorológicas</strong> pueden ser consideradas de
          interés al{" "}
          <strong>analizar la incidencia y propagación de la enfermedad</strong>
          COVID-19 en España.
        </ModTwoCols>
        <ModDashedLine />
        <ModDataEu fecha={"2020-04-14"} />
        <ModText>
          Hoy entra en vigor la <strong>orden SND/344/2020</strong> impulsada
          por el Ministerio de Sanidad, en la que se establecen medidas
          excepcionales para el{" "}
          <strong>refuerzo del Sistema Nacional de Salud</strong> y la
          contención de la crisis sanitaria ocasionada por el COVID-19.
        </ModText>
        <ModText>
          Las <strong>medidas</strong> que contempla la orden son las
          siguientes:
        </ModText>
        <ModDotList
          text1="Las comunidades autónomas tendrán a su
          disposición los centros, servicios y establecimientos sanitarios
          de diagnóstico clínico de titularidad privada, que no estén
          prestando servicio en el Sistema Nacional de Salud, así como su
          personal."
          text2="Fijan los requisitos para la
          realización de pruebas diagnósticas para la detección de COVID-19."
          text3="Todos los centros, con independencia de
          su titularidad, deberán notificar a las CCAA los casos de COVID-19
          confirmados de los que hayan tenido conocimiento tras la
          realización de las pruebas diagnósticas."
          text4="Las entidades públicas o privadas que
          adquieran material para hacer pruebas diagnósticas deberán
          notificarlo, con indicación expresa del tipo de material, número
          de unidades adquiridas y destino de uso."
          text5="Se incluye la posibilidad de adoptar
          medidas necesarias para la regulación de los precios de estas
          pruebas, con el objeto de evitar situaciones abusivas en el acceso
          a este servicio."
        />
      </ContentLeft>

      <ContentRight>
        <ModText>
          En <strong>España</strong>, el balance del día se salda con el
          registro de 3.045 nuevos positivos y 517 personas fallecidas.
          Permanecen hospitalizadas 75.229 personas, 1.764 más que el día
          anterior, y 2.336 reciben el alta. El número de casos ingresados en la
          UCI asciende a 7.777, lo que supone un aumento de 223 personas.
        </ModText>
        <ModText>
          Respecto al número de casos totales confirmados, la tasa de mortalidad
          es del 10,3 % y la tasa de recuperados del 38,2 %.
        </ModText>
        <ModText>
          Por otra parte, CSIC ha empezado a{" "}
          <strong>
            utilizar datos de móviles para estudiar la eficacia del
            confinamiento
          </strong>{" "}
          sobre la dispersión del coronavirus. El nuevo proyecto analiza datos
          masivos anónimos que se están obteniendo desde operadores de telefonía
          y servidores de mapas, para explicar cómo ha cambiado la movilidad.
        </ModText>
        <ModText>
          Los resultados van a ser clave para analizar cómo se puede levantar el
          confinamiento y mejorar las estrategias que se tomen en esta y en
          futuras epidemias.
        </ModText>
        <ModImage src="/images/svg/14_abr_test_PCR.svg" alt="realizar test PCR" />
        <ModText>
          El Instituto de Salud Carlos III ha validado, en coordinación con el
          Ministerio de Ciencia e Innovación, la capacitación de ocho{" "}
          <strong>nuevos centros</strong> de investigación y universidades para
          que puedan <strong>realizar test de PCR</strong> para el diagnóstico
          de la enfermedad COVID-19.
        </ModText>
        <ModTwoCols
          src="/images/svg/14_abr_productos_limpieza.svg"
          alt="intoxicaciones por mezclar productos limpieza"
          small={true}
        >
          Por último, el Instituto Nacional de Toxicología advierte de un{" "}
          <strong>
            aumento significativo en las intoxicaciones por la mezcla de
            productos de limpieza
          </strong>{" "}
          a raíz de la pandemia. Los médicos del SIT han atendido durante el mes
          de marzo y las dos primeras semanas de abril 11.337 consultas
          telefónicas, lo que supone un incremento de 1.655 llamadas con
          respecto al mismo periodo del año 2019.
        </ModTwoCols>
        <ModGraph
          name="1404-HospitalizadosPrevalencia-ES-AN"
          alt="grafico de hospitalizados por covid-19 en Andalucia por provincias"
        />
        <ModReferenceList title="GUÍAS Y DOCUMENTOS PUBLICADOS">
          <ReferenceRow
            link="https://www.boe.es/buscar/act.php?id=BOE-A-2020-4442"
            name="Orden SND/344/2020 del Ministerio de Sanidad"
          />
          <ReferenceRow
            link="https://www.lamoncloa.gob.es/serviciosdeprensa/notasprensa/transicion-ecologica/Paginas/2020/140420-correlacion.aspx"
            name="Correlación entre variables metereológicas y propagación de COVID-19"
          />
        </ModReferenceList>
      </ContentRight>
    </LayoutDay>
  );
};
export default Day32;
